import React from 'react';

import Layout from '../components/Layout';
import Footer from '../components/Footer';
import Header from '../components/Header';
import SideFooter from '../components/SideFooter';

import heroImage from '../assets/images/lw.jpg';
import ContactForm from '../components/ContactForm';
import { Link } from 'gatsby';


const IndexPage = () => (
  <Layout>
    <div id="wrapper">
      <section className="intro">
        <Header />
        <div className="content">
          <span className="image fill" data-position="center">
            <img src={heroImage} alt="" />
          </span>
        </div>
      </section>

      <section id="first">
        <header>
          <h2>Reaktywne programowanie dla zaawansowanych - RxJS</h2>
        </header>
        <div className="content">
          <p>
            <strong>Szkolenie dla zaawansowanych</strong> programistów Javascript chcących poszerzyć swoją wiedzę w dziedzinie 
            programowania funkcyjnego. 
            Nauka programowania reaktywnego obejmuje zarówno obsługę działań po stronie klienta jak i serwera. 
            Prześledzimy drogę od programowania imperatywngeo do architektury opartej o asynchroniczny strumień danych (Observable streams). 
            Szkolenie oparte jest na praktycznych przykłach i budowaniu rzeczywistej aplikacji ze szczególnym uwzględnieniem 
            reaktywnego zarządzania stanem aplikacji webowej. Ostatnia część kursu obejmuje zagadnienia z testowania kodu opartego o RxJS.
          </p>
        </div>
      </section>
      <section id="sec">
        <header>
          <h2>Tworzenie nowoczesnych aplikacji internetowych w praktyce (React, Flux, Redux, ReactNative)</h2>
        </header>
        <div className="content">
          <p>
            <strong>Szkolenie dla programistów</strong> swobodnie poruszających się w zagadnieniach Javascript ES6. Na kursie zostaną omówione
            zagadnienia tworzenia aplikacji webowych SPA w React. Szczególnie duży nacisk położymy na programowanie hooków w
            Ract oraz tworzenie własnych. Następnie przedstawione zostaną metody testowania aplikacji reactowych. Ostatnia cześć 
            poświęcona zostanie tworzeniu aplikacji mobilnych z użyciem ReactNative.
          </p>
        </div>
      </section>
      <section id="third">
        <header>
          <h2>Kurs programowania w języku GO - fundamenty</h2>
        </header>
        <div className="content">
          <p>
            <strong>Szkolenie dla programistów</strong> znających inne języki programowania. Kurs omawia takie podstawy jak m.in: 
            struktury danych, funkcje, interfejsy i współbieżność. W części praktycznej zbudowane zostanie proste API oraz serwer HTTP. Całość zostanie 
            połączona z bazą danych (PostgreSQL).
          </p>
        </div>
      </section>
      <section id="fourth">
        <header>
          <h2>Nowoczesne metody organizacji pracy zdalnej w firmie IT</h2>
        </header>
        <div className="content">
          <p>
            <strong>Szkolenie dla administratrów</strong> systemów sieciowych w firmie. Kurs obejmuje zagadnienia konfiguracji 
            narzędzi i usług online (Github, Bitbucket, Jira, Google Workspace, MsTeems) dla zespołów programistycznych. Omówione zostaną także aspekty 
            utrzymania bezpieczeństwa wymiany wrażliwych danych pomiędzy pracownikami a infrastrukturą 
            firmową (szyfrowanie poufnaych danych, polityka bezpiecznych haseł, narzędzia typu Keepass czy TrueCrypt).
          </p>
        </div>
      </section>


      <section>
        <ContactForm />
        <section id="fifth">
        <header>
          <h3>Adres</h3>
        </header>
        <div className="content">
          
          <p>
          ul. Hiacyntowa 14, 55-095 Mirków
          </p>
        </div>
      </section>
        <SideFooter />
      </section>

      <Footer />
    </div>
  </Layout>
);

export default IndexPage;
