import React from 'react';
import Scroll from './Scroll';

export default function Header() {
  return (
    <header>
      <h1>Łukasz <span style={{fontSize: '0.75em'}}>Wojciechowski</span></h1>
      <p>Programowanie,<br /> konsulting, szkolenia</p>
      <ul className="actions">
        <li>
          <Scroll type="id" element="first">
            <a href="#first" className="arrow">
              <span className="label">Next</span>
            </a>
          </Scroll>
        </li>
      </ul>
    </header>
  );
}
